import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade } from 'swiper'
import Layout from '../layouts/Layout'
import slide1 from '../images/slide_1.jpg'
import ashibi from '../images/logo_b.png'
import slide2 from '../images/slide_2.jpg'
import slide3 from '../images/slide_3.jpg'
import slide4 from '../images/slide_4.jpg'
import SEO from '../components/SEO'

import 'swiper/css'
import 'swiper/css/effect-fade'

const IndexPage = () => {
  return (
    <Layout>
      <div className="flex flex-col items-center mb-6">
        <img className="w-48 lg:w-auto" src={ashibi} alt="ashibi-logo" />
        {/* <h1 className="text-4xl text-center leading-loose mb-6 font-medium">
          とんかつの店馬酔木
        </h1> */}
      </div>
      <div className="flex justify-center mb-10">
        <Swiper
          className="w-full lg:w-4/5 min-h-[300px] rounded-lg"
          autoplay
          loop
          modules={[Autoplay, EffectFade]}
          delay={300}
          effect="fade"
        >
          <SwiperSlide>
            <img src={slide1} alt="ashibi-food" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide2} alt="ashibi-food" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide3} alt="ashibi-food" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide4} alt="ashibi-food" />
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="flex items-center flex-col">
        <div className="text-center w-full lg:w-1/2 mb-10">
          <h2 className="text-2xl font-medium mb-2">~馬酔木の由来~</h2>
          <p className="leading-7">
            その昔、人々が馬に荷を乗せて旅をしていた頃、
            一息入れるため馬を木につなぐとその木の香りに馬が酔ったことから、
            馬酔木 あしびと名付けられました。
            又古典に学ぶと"栄える”の枕詞は馬酔木となっています。
            私どもはお客様に少しでも様々な意味で「酔って」 いただければと思い、
            この名を店名に使わせていただきました。
          </p>
        </div>
        <div className="text-center w-full lg:w-1/2">
          <h2 className="text-2xl font-medium mb-2">~厳選された食材を使用~</h2>
          <p className="leading-7">
            馬酔木では、お肉はもとより、 お末、お野菜、
            その他全ての厳選した食材を、 より良くお客様に
            お届けすることを第一に考えております。
            その内容は馬酔木で用意する食材をご覧下さいませ。
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => <SEO title="とんかつの店 馬酔木 横浜市都筑区" />

export default IndexPage
